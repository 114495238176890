import { Controller } from '@hotwired/stimulus';
import 'select2';
import DOMPurify from 'dompurify';

import { visit } from 'lib/visit';
import { select2GroupMatcher } from './select2_extensions';

/**
 * select2 controller.
 *
 * A customizable select box with support for searching, tagging, remote data sets,
 * infinite scrolling, etc.
 *
 * https://select2.org
 *
 * Options can be passed in as data-* attributes:
 * https://select2.org/configuration/options-api
 *
 * NOTE: if we need to allow options that select2 doesn't recognize through
 * data attributes, use the technique from stimulus-flatpickr:
 * https://github.com/adrienpoly/stimulus-flatpickr/blob/master/src/index.js#L43
 *
 * Values:
 * - hideDisabledOptions {boolean}: if true and the option element has the
 *    disabled attribute, the option will be hidden in the search results.
 * - htmlOptions {boolean}: if true and the option element has a data-html
 *    attribute, its sanitized value is used as HTML to represent the option
 *    (in both results and selection); or data-result-html and data-selection-html
 *    can be used to target just one or the other.
 */
export default class extends Controller {
  static values = {
    allowClear: Boolean,
    closeOnSelect: Boolean,
    hideDisabledOptions: Boolean,
    htmlOptions: Boolean,
    maximumSelectionLengthVisible: Number,
    minimumInputLength: Number,
    tags: Boolean,
    width: String,
  };

  // Global config options
  static config = {
    matcher: select2GroupMatcher,
    placeholder: '',
    width: 'resolve',
    dropdownAutoWidth: true,
    // Use bootstrap 5 structure and classes.  To use the default theme, change
    // this to undefined.
    // https://github.com/apalfrey/select2-bootstrap-5-theme
    theme: 'bootstrap-5',
  };

  // Global config options for multiple select fields (which are merged into
  // the above config)
  static multipleConfig = {
    closeOnSelect: false,
    maximumSelectionLengthVisible: 10,
    dropdownAdapter: $.fn.select2.amd.require('select2/dropdown/multiselect'),
  };

  initialize() {
    // Static config based on whether this is a single or multiple select
    if (this.isMultiple) {
      this.config = {
        ...this.constructor.config,
        ...this.constructor.multipleConfig,
      };
    } else {
      this.config = { ...this.constructor.config };
    }

    this.config.templateResult = this.templateResult.bind(this);
    this.config.templateSelection = this.templateSelection.bind(this);

    // Overrides for static config based on element data

    if (this.hasAllowClearValue) {
      this.config.allowClear = this.allowClearValue;
    }

    if (this.hasCloseOnSelectValue) {
      this.config.closeOnSelect = this.closeOnSelectValue;

      if (this.config.closeOnSelect && this.config.dropdownAdapter) {
        // select2 ignores the closeOnSelect config value if a dropdownAdapter
        // is being supplied (because closeOnSelect is implemented via an adpater).
        // In that case, we need to import the select2 Utils and the CloseOnSelect
        // adapter and apply them to our custom dropdownAdapter.
        const CloseOnSelect = $.fn.select2.amd.require(
          'select2/dropdown/closeOnSelect',
        );
        const Utils = $.fn.select2.amd.require('select2/utils');
        this.config.dropdownAdapter = Utils.Decorate(
          this.config.dropdownAdapter,
          CloseOnSelect,
        );
      }
    }

    if (this.hasMinimumInputLengthValue) {
      this.config.minimumInputLength = this.minimumInputLengthValue;
    }

    if (this.hasMaximumSelectionLengthVisibleValue) {
      this.config.maximumSelectionLengthVisible =
        this.maximumSelectionLengthVisibleValue;
    }

    if (this.hasTagsValue) {
      this.config.tags = this.tagsValue;
    }

    if (this.element.getAttribute('placeholder')) {
      this.config.placeholder = this.element.getAttribute('placeholder');
    }

    if (this.hasWidthValue) {
      this.config.width = this.widthValue;
    }
  }

  connect() {
    // Select2 doesn't use native events, so dispatch a native change here
    // so that it will trigger stimulus actions.
    // https://github.com/stimulusjs/stimulus/issues/211
    const select2Events =
      'select2:select.stimulus select2:unselect.stimulus select2:clear.stimulus';

    const $element = $(this.element);

    $element.select2({ ...this.config }).on(select2Events, (originalEvent) => {
      const event = new CustomEvent('change', {
        bubbles: true,
        cancelable: true,
        detail: { originalEvent },
      });
      this.element.dispatchEvent(event);
    });

    // Fix a select2 bug that leaves typed text when an item selected in multiselect
    // https://github.com/select2/select2/issues/1758
    if (this.isMultiple) {
      $element.on('select2:select', () => {
        const searchField = this.element.nextSibling.querySelector(
          '.select2-search__field',
        );

        if (searchField?.value) {
          searchField.value = '';

          // Trick to restore cursor editing position
          $element.select2('close').select2('open');
          searchField.blur();
          searchField.focus();
        }
      });
    }
  }

  disconnect() {
    const $element = $(this.element);

    this.saveState();

    $element.off('.stimulus');

    if ($element.data('select2')) {
      $element.select2('destroy');
    }
  }

  // Save the selected values from the select2 instance to the HTML
  saveState() {
    const $element = $(this.element);
    const values = $element.val();

    if (!Array.isArray(values)) {
      return;
    }

    values.forEach((val) => {
      $element.find(`option[value="${val}"]`).attr('selected', 'selected');
    });
  }

  templateResult(result, container) {
    if (this.hasHideDisabledOptionsValue) {
      container.classList.toggle('hide', result.element?.disabled);
    }

    if (this.htmlOptionsValue && result.element?.dataset?.resultHtml) {
      return $(DOMPurify.sanitize(result.element.dataset.resultHtml));
    }

    if (this.htmlOptionsValue && result.element?.dataset?.html) {
      return $(DOMPurify.sanitize(result.element.dataset.html));
    }

    return result.text;
  }

  templateSelection(result) {
    if (this.htmlOptionsValue && result.element?.dataset?.selectionHtml) {
      return $(DOMPurify.sanitize(result.element.dataset.selectionHtml));
    }

    if (this.htmlOptionsValue && result.element?.dataset?.html) {
      return $(DOMPurify.sanitize(result.element.dataset.html));
    }

    return result.text;
  }

  /**
   * Returns true if this is a multiple select, false otherwise.
   */
  get isMultiple() {
    return this.element.hasAttribute('multiple');
  }

  /**
   * Navigate to the element value, assuming it's a URL.
   */
  navigate() {
    visit(this.element.value);
  }
}
