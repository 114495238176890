import { Controller } from '@hotwired/stimulus';

/**
 * ads--ad-placement controller: Places the ads on the issue page into the
 * desired positions (after the second, third and forth posting right now).
 * Any other ads that remain (e.g. if there is only one posting) will not be
 * moved to a different placed in the DOM.
 */
export default class extends Controller {
  static targets = ['ad', 'adSlot'];

  connect() {
    const positions = [1, 2, 3];

    let pos;
    let slot;
    this.adTargets.forEach((ad, i) => {
      pos = positions[i];
      if (pos !== undefined) {
        slot = this.adSlotTargets[pos];
        if (slot !== undefined) {
          slot.after(ad); // move ad in the defined position
        }
      }
    });
  }
}
